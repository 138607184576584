import React from 'react';

function Experience() {
  return (
    <section className="tc-experience-style1 section-padding-x">
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-2">
            <div className="exp-num mb-100 wow zoomIn" data-wow-delay="0.3s">
              <h5 className="fsz-18 text-uppercase">
                years of <br /> experience
              </h5>
              <h2 className="num"> 12 </h2>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="img wow">
              <img
                src="/home1/assets/img/DB-architekt-ADS-office interior-lobby.jpg"
                alt=""
                className="img-cover"
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="info wow fadeInUp" data-wow-delay="0.3s">
              <h3 className="fsz-45 fw-600 mb-30"> Est. 2012 </h3>
              <div className="text fsz-15 color-666">
              DB ARCHITEKT is an award winning creative consultancy firm dealing in Architectural & Interior design consulting across India. We started Practice in 2012 in the name of QONE Design and Rebrand as DB Architekt in 2018 with unique Design Philosophy of “Design Beyond” for happy client.
              </div>
              <a
                href="/innerpages/about"
                className="butn rounded-pill mt-50 hover-bg-black bg-white"
              >
                <span>
                  Know More ... <i className="small ms-1 ti-arrow-top-right"></i>
                </span>
              </a>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="rotate-txt justify-content-lg-end">
              <ul>
                <li>
                  <a href="db@dbarchitekt.com"> db@dbarchitekt.com </a>
                </li>
                <li>
                  <a href="011 - 40079310"> 011 - 40079310 </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <img src="/home1/assets/img/c_line.png" alt="" className="c-line wow" />
    </section>
  );
}

export default Experience;
