import React, { useState } from 'react';

function Comments() {
  const [comments, setComments] = useState([
    {
      id: 1,
      name: 'David Bowie',
      time: '3 hours ago',
      message:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      image: '/innerpages/assets/img/team/team1.jpg',
    },
    {
      id: 2,
      name: 'Logan Cee',
      time: '2 days ago',
      message:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      image: '/innerpages/assets/img/team/team2.jpg',
    },
    {
      id: 3,
      name: 'Luis Diaz',
      time: 'December 25, 2023',
      message:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.',
      image: '/innerpages/assets/img/team/team3.jpg',
    },
  ]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Adding new comment
    const newComment = {
      id: comments.length + 1,
      name: formData.name,
      time: 'Just now',
      message: formData.message,
      image: '/innerpages/assets/img/team/team4.jpg', // You can change this to a default user image or dynamically load it
    };
    setComments([...comments, newComment]);
    // Reset form after submitting
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <section className="tc-comments-style1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="comments-content">
              <div className="content">
                <h3 className="fsz-45 text-capitalize mb-30">Comments</h3>
                <div className="comments-cards">
                  {comments.map((comment) => (
                    <div
                      key={comment.id}
                      className="comment-replay-cont border-bottom border-1 brd-gray2 pb-40 pt-40"
                    >
                      <div className="d-flex comment-cont">
                        <div className="icon-60 rounded-circle overflow-hidden me-3 flex-shrink-0">
                          <img
                            src={comment.image}
                            alt={comment.name}
                            className="img-cover"
                          />
                        </div>
                        <div className="inf w-100">
                          <div className="title d-flex justify-content-between">
                            <h6 className="fw-bold fsz-14">{comment.name}</h6>
                            <span className="time fsz-12 text-uppercase color-999">
                              {comment.time}
                            </span>
                          </div>
                          <div className="text color-666 fsz-14 mt-10">
                            {comment.message}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <form
                  className="comment-form d-block pt-100"
                  onSubmit={handleFormSubmit}
                >
                  <h3 className="mb-60 fw-bold text-capitalize fsz-45">
                    Leave A Comment
                  </h3>
                  <p className="color-666 mb-90">
                    Your email address will not be published. Required fields
                    are marked <span className="text-danger"> * </span>
                  </p>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-40">
                        <label htmlFor="message" className="fsz-13 text-uppercase">
                          Message
                        </label>
                        <textarea
                          className="form-control"
                          name="message"
                          rows="6"
                          placeholder="Write your message here"
                          value={formData.message}
                          onChange={handleInputChange}
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-40">
                        <label htmlFor="name" className="fsz-13 text-uppercase">
                          Your Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          placeholder="Your name here"
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-40">
                        <label htmlFor="email" className="fsz-13 text-uppercase">
                          Your Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Your email address"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12 mt-50">
                      <button
                        type="submit"
                        className="butn rounded-pill hover-bg-orange1 bg-white mt-20 fsz-16 text-capitalize"
                      >
                        Post Comment
                        <i className="fal fa-arrow-up-right ms-2"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Comments;
