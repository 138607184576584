import React from 'react';

function Portfolio() {
  return (
    <section className="tc-portfolio-style4">
      <div className="container">
        <div className="content">
          <div className="title js-splittext-lines">
            <h2 className="sec-title mb-30"> latest works </h2>
            <p className="fsz-18 color-666">
              Creativity is at the heart in <br /> all my projects
            </p>
          </div>
          <div className="projects">
            <a href="#" className="work-card">
              <div className="img wow">
                <img
                  src="/home4_personal_architect/assets/img/works/DB-architekt-house-interior-formal drawing room-view.jpg"
                  alt=""
                  className="img-cover"
                />
              </div>
              <h5> House Interior </h5>
            </a>
            <a href="#" className="work-card">
              <div className="img wow">
                <img
                  src="/home4_personal_architect/assets/img/works/DB-architekt-kings kulfi-moti nagar-newdelhi-2.jpg"
                  alt=""
                  className="img-cover"
                />
              </div>
              <h5> King Kulfi </h5>
            </a>
            <a href="#" className="work-card">
              <div className="img wow">
                <img
                  src="/home4_personal_architect/assets/img/works/DB-architekt-HOMES-INTERIOR-dinning view-dwarka.jpg"
                  alt=""
                  className="img-cover"
                />
              </div>
              <h5> Homes Interior </h5>
            </a>
            <a href="#" className="work-card">
              <div className="img wow">
                <img
                  src="/home4_personal_architect/assets/img/works/DB-architekt_FURNITURE STORE-03.jpg"
                  alt=""
                  className="img-cover"
                />
              </div>
              <h5> Furniture Store </h5>
            </a>
            <a href="#" className="work-card">
              <div className="img wow">
                <img
                  src="/home4_personal_architect/assets/img/works/DB-architekt-farm house-interior-master bedroom view.jpg"
                  alt=""
                  className="img-cover"
                />
              </div>
              <h5> Farm House Interior </h5>
            </a>
            <a href="#" className="work-card">
              <div className="img wow">
                <img
                  src="/home4_personal_architect/assets/img/works/DB-architekt-kings-kulfi-sankar-road-newdelhi-view-3.jpg"
                  alt=""
                  className="img-cover"
                />
              </div>
              <h5> King Kulfi Sankar Road New Delhi </h5>
            </a>
          </div>
          <h4 className="pr-link">
            <a href="/innerpages/portfolio" className="js-splittext-lines">
              view all <br /> works
            </a>
            <span className="icon js-splittext-lines">
              <i className="ti-arrow-top-right"></i>
            </span>
          </h4>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
