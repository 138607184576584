import React from 'react';

function Services() {
  return (
    <section className="tc-services-style1">
      <div className="content section-padding section-padding-x">
        <div className="container">
          <div className="title mb-80 text-center">
            <p className="color-666 text-uppercase wow"> our services </p>
          </div>
          <div className="services">
            <div className="row">
              <div className="col-lg-3">
                <a
                  href="/innerpages/services"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <div className="icon">
                    <i className=""></i>  
                  </div>
                  <h5 className="fsz-24 mb-20">Architecture</h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/services/ser.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                  Innovative, sustainable architecture transforms spaces by blending functionality with creative design, enhancing environments through modern, eco-friendly building solutions. Experience thoughtful architectural excellence.
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </a>
              </div>
              <div className="col-lg-3">
                <a
                  href="/innerpages/services"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20">Interior</h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/services/ser1.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                  Interior design merges aesthetics with functionality, creating harmonious spaces that reflect personality, maximize comfort, and elevate everyday living experiences.
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </a>
              </div>
              <div className="col-lg-3">
                <a
                  href="/innerpages/services"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20">Design</h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/services/ser2.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                  Design shapes ideas into reality, blending creativity, innovation, and purpose to craft visually compelling and functional solutions for diverse needs.
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </a>
              </div>
              <div className="col-lg-3">
                <a
                  href="/innerpages/services"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <div className="icon">
                    <i className=""></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                  Design & Build
                  </h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/services/ser3.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                  Design and build integrate creative vision with construction expertise, streamlining the process to deliver cohesive, efficient, and custom-built environments from concept to completion.
                  </div>
                  <span className="arrow">
                  <i className="ti-arrow-top-right"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="text-center">
            <a
              href="#" className="butn rounded-pill mt-80 hover-bg-black bg-orange1 text-white">
              <span>
                Let's Talk
                <i className="small ms-1 ti-arrow-top-right"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="ser-img">
        <img src="/home1/assets/img/services/ser4.jpg" alt="" />
      </div>
    </section>
  );
}

export default Services;
