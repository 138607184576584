import React, { useState } from 'react';

const JobApplicationForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        position: '',
        resume: null,
    });

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'file' ? files[0] : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Data Submitted:', formData);
        // Code to send formData to a backend or save it in an Excel sheet would go here.
    };

    return (
        <section className="tc-contact-form-style1">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-lg-4">
                        <div className="info wow fadeInUp slow" data-wow-delay="0.2s">
                            <h3 className="fsz-45 fw-500 mb-80">
                                Job & Internship Apply
                            </h3>
                            <p className="fsz-14 color-666 mt-15">
                                We appreciate your interest in joining our team. Please fill out the form below to apply for a job or internship position. Required fields are marked <span className="text-danger"> * </span>
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <form
                            onSubmit={handleSubmit}
                            className="form mt-5 mt-lg-0 wow fadeInUp slow"
                            data-wow-delay="0.4s"
                        >
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group mb-30">
                                        <label htmlFor="name">
                                            Name <span className="color-orange1"> * </span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Your name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-30">
                                        <label htmlFor="email">
                                            Email <span className="color-orange1">*</span>
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Your email address"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-30">
                                        <label htmlFor="phone">
                                            Phone <span className="color-orange1">*</span>
                                        </label>
                                        <input
                                            type="tel"
                                            className="form-control"
                                            placeholder="Your phone number"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-30">
                                        <label htmlFor="position">
                                            Position Applying For <span className="color-orange1">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Position or Internship Title"
                                            name="position"
                                            value={formData.position}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-30">
                                        <label htmlFor="resume">
                                            Resume <span className="color-orange1">*</span>
                                        </label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            name="resume"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="butn hover-bg-orange1 text-capitalize bg-white rounded-pill mt-40"
                            >
                                <span>
                                    Submit Application
                                    <i className="fal fa-arrow-up-right ms-2"></i>
                                </span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <img
                src="/innerpages/assets/img/contact_shap.png"
                alt=""
                className="shap"
            />
        </section>
    );
};

export default JobApplicationForm;
