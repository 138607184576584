import React from 'react';

function Control() {
  return (
    <div className="full-page-pagination">
      <div className="swiper-pagination"></div>
    </div>
  );
}

export default Control;
