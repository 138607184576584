import React from 'react';

function About() {
  return (
    <section className="tc-about-style7">
      <div className="container">
        <div className="row gx-0 justify-content-between">
          <div className="col-lg-3">
            <div className="rotate-box" data-speed="1" data-lag="0.4">
              <a
                href="#"
                className="rotate-circle fsz-30 rotate-text d-inline-block text-uppercase"
              >
                <svg className="textcircle" viewBox="0 0 500 500">
                  <defs>
                    <path
                      id="textcircle"
                      d="M250,400 a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
                    ></path>
                  </defs>
                  <text>
                    <textPath xlinkHref="#textcircle" textLength="900">
                      Landscape design - DB Architekt -
                    </textPath>
                  </text>
                </svg>
              </a>
              <img
                src="/home7_landscape/assets/img/leafs.png"
                alt=""
                className="icon"
              />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="info wow fadeInUp slow" data-wow-delay="0.2s">
              <div className="text fsz-25 fw-500 mb-20">
              As architect always want to turning dream in to reality with at client ease.

Every client small or big, they invest their money, time, resource, family values and very importantly sentimental to the building/interior work they make.

We personally want to take this to another level by creative design, detailing, sourcing, construction, lighting, styling with transparent, efficient and systematic manner to complete it on time.

To take one more level up, we design the building as per your astrological chart position based vastu planning to create, success, happiness, joy, health & wealth to my client that we call it design beyond philosophy for your best future & sustainable world.

              </div>
              <div className="text fsz-25 fw-500 mb-20">
              Good ideas create RELIABLE DESIGNS. 
              </div>
              <a
                href="#"
                className="butn border rounded-pill border-green1 hover-bg-green1 text-capitalize color-green1 mt-70"
              >
                <span> More About Us </span>
              </a>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="numbers mt-5 mt-lg-0">
              <div
                className="number-card wow zoomIn slow"
                data-wow-delay="0.4s"
              >
                <h2 className="numb"> 95% </h2>
                <small> Clients Satisfied and repeating </small>
              </div>
              <div
                className="number-card wow zoomIn slow"
                data-wow-delay="0.6s"
              >
                <h2 className="numb"> 125% </h2>
                <small> projects done </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
