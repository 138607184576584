import React from 'react';

function Process() {
  return (
    <section className="tc-process-style1">
      <div className="container">
        <div className="title mb-100 text-center">
          <h2 className="fsz-45"> Our Process Work </h2>
        </div>
        <div className="content">
          <div className="row">
            <div className="col-lg-5">
              <div className="info">
                <div
                  className="accordion wow fadeInUp slow"
                  id="accordionProcess"
                >
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        <span className="num"> 1 / </span>
                        <h3> Design Philosophy </h3>
                      </button>
                    </div>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                        Design philosophy encompasses the guiding principles and values that shape a designer's work. It emphasizes the importance of functionality, aesthetics, sustainability, and user experience. A strong design philosophy seeks to create meaningful connections between people and their environments, prioritizing simplicity and clarity. By balancing creativity with practicality, designers aim to solve problems and enhance the quality of life through thoughtful and innovative solutions.

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        <span className="num"> 2 / </span>
                        <h3>  Collaborative Process </h3>
                      </button>
                    </div>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                        The collaborative process involves bringing together diverse perspectives and expertise to achieve common goals. It begins with establishing clear communication channels and defining roles within the team. Through brainstorming sessions and open discussions, ideas are shared and refined, fostering creativity. Regular feedback loops are crucial for aligning objectives and making necessary adjustments. By nurturing a culture of trust and respect, collaboration enhances problem-solving and innovation, leading to more effective outcomes and a sense of shared ownership among all participants.

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                      >
                        <span className="num"> 3 / </span> <h3> Project Lifecycle </h3>
                      </button>
                    </div>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                        The project lifecycle encompasses the stages a project goes through from initiation to completion. It typically includes five phases: initiation, where the project idea is defined and feasibility is assessed; planning, which involves setting objectives, creating timelines, and allocating resources; execution, where the project plan is implemented; monitoring and controlling, which ensures progress aligns with goals and addresses any issues; and closure, where the project is finalized, results are evaluated, and lessons learned are documented. This structured approach helps ensure projects are completed on time, within budget, and to the desired quality standards.

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  href="/innerpages/portfolio"
                  className="butn border rounded-pill mt-80 color-orange1 border-orange1 hover-bg-orange1"
                >
                  <span>
                    Our Projects
                    <i className="small ms-1 ti-arrow-top-right"></i>
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-4 offset-lg-2">
              <div className="img wow">
                <img
                  src="/innerpages/assets/img/DB-architekt-architecture-farm house-aerial view.jpg"
                  alt=""
                  className="img-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="/innerpages/assets/img/c_line2.png"
        alt=""
        className="c-line wow"
      />
    </section>
  );
}

export default Process;
