import React from 'react';

function ContactInfo() {
  return (
    <section className="tc-contact-info-style1">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="info-cards">
              <div className="row">
                <div className="col-lg-6">
                  <div className="item mt-50">
                    <h6 className="fsz-24 mb-15 fw-bold"> New Delhi India </h6>
                    <ul className="color-666 lh-6">
                      <li>
                        <a href="#"> 10,Basement,Vinobapuri
                        Lajpat Nagar-2, New Delhi-110024 </a>
                      </li>
                      <li>
                        <a href="mailto:db@dbarchitekt.com"> db@dbarchitekt.com </a>
                      </li>
                      <li>
                        <a href="011 - 40079310"> 011 - 40079310 </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="col-lg-6">
                  <div className="item mt-50">
                    <h6 className="fsz-24 mb-15 fw-bold"> New Delhi </h6>
                    <ul className="color-666 lh-6">
                      <li>
                        <a href="#">10,Basement,Vinobapuri
                        Lajpat Nagar-2, New Delhi-110024</a>
                      </li>
                      <li>
                        <a href="db@dbarchitekt.com"> db@dbarchitekt.com </a>
                      </li>
                      <li>
                        <a href="011 - 40079310"> 011 - 40079310 </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="social-icons mt-50 text-lg-end">
              <a href="#">
                <i className="fab fa-x-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://www.instagram.com/db.architekt/">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://in.linkedin.com/company/dbarchitekt">
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href="https://www.youtube.com/channel/UC2Vxn_hmgyO_tloro3RH-8Q">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactInfo;
