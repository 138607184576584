import React from 'react';

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark tc-navbar-style1 section-padding-x">
      <div className="container-fluid content">
        <a className="navbar-brand" href="/home1">
          <img src="/home1/assets/img/DB-Architekt-logo.png" alt="DB Architekt Logo" className="logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="/home1">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/innerpages/about">
                About
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/innerpages/portfolio">
                Cases
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/innerpages/blog">
                Blog
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/innerpages/services">
                Service
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/innerpages/Sector">
                Sector
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/innerpages/contact">
                Contact
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" href="/home3_construction">
                Construction
              </a>
            </li> */}
            {/* <li className="nav-item">
              <a className="nav-link" href="/home5_residence">
                Residence
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/home6_furniture">
                Furniture
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/home7_landscape">
                Landscape
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/home3_construction">
                Landscape360
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/innerpages/single_post">
                Single post
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/innerpages/single_project">
                Single project
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/innerpages/single_project2">
                Single project 2
              </a>
            </li> */}
          </ul>
          <div className="nav-side">
            <a href="#" className="icon ms-3">
              <span>  </span>
            </a>
            <a href="#" className="icon ms-3">
              <span>  </span>
            </a>
            <a href="#" className="icon ms-5 fsz-21">
              <span>
                <i className="la la-search"></i>{' '}
              </span>
            </a>
            <a href="#" className="icon ms-3 side_menu_btn fsz-21">
              <span>
                <i className="la la-grip-lines"></i>{' '}
              </span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
