import React, { useState } from 'react';

const VendorRegistration = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        service: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Data Submitted:', formData);
        // Code to send the formData to a backend or save it in an Excel sheet would go here.
    };

    return (
        <section className="tc-contact-form-style1">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-4">
              <div className="info wow fadeInUp slow" data-wow-delay="0.2s">
                <h3 className="fsz-45 fw-500 mb-80">
                  Vender
                </h3>
                <p className="fsz-14 color-666 mt-15">
                We appreciate your correspondence, dear suppliers, vendors, and service providers. 
Please register your business here as well as yourself. 
We are going to contact you soon. It will be a pleasure working with you.<br /> Required fields
                  are marked <span className="text-danger"> * </span>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
            <form
              action=""
              className="form mt-5 mt-lg-0 wow fadeInUp slow"
              data-wow-delay="0.4s"
            >
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group mb-30">
                    <label htmlFor="">
                      Name <span className="color-orange1"> * </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="your name"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-30">
                    <label htmlFor="">
                      email <span className="color-orange1">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your email address"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-30">
                    <label htmlFor="">
                      Phone <span className="color-orange1">*</span> <span className="color-999"></span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your phone number"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-30">
                    <label htmlFor="">
                      Company Name <span className="color-orange1">*</span> <span className="color-999"></span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your phone number"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-30">
                    <label htmlFor="">
                      Service Details <span className="color-orange1">*</span> <span className="color-999"></span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your phone number"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-30">
                    <label htmlFor="">
                      DOC <span className="color-999"></span>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      placeholder="Your phone number"
                    />
                  </div>
                </div>
                { <div className="col-lg-6">
                  <div className="form-group mb-30">
                    <label htmlFor="">
                      Select (Required) <span className="color-orange1"> * </span>
                    </label>
                    <select name="" id="" className="form-select form-control">
                      <option value=""> Please select one of the following </option>
                      <option value=""> Architecture Product </option>
                      <option value=""> Interior Product </option>
                      <option value=""> Consultant </option>
                      <option value=""> Other </option>
                    </select>
                  </div>
                </div>}
              </div>
              <a
                href="#"
                className="butn hover-bg-orange1 text-capitalize bg-white rounded-pill mt-40"
              >
                <span>
                  Register
                  <i className="fal fa-arrow-up-right ms-2"></i>
                </span>
              </a>
            </form>
              
            </div>
          </div>
        </div>
        <img
          src="/innerpages/assets/img/contact_shap.png"
          alt=""
          className="shap"
        />
      </section>
    );
};

export default VendorRegistration;