import React from 'react';

function Menu() {
  return (
    <>
      <div className="side_menu4_overlay"></div>
      <div className="side_menu4_overlay2"></div>
      <div className="side_menu_style4">
        <div className="content">
          <div className="main_links">
            <ul>
              <li>
                <a href="/home1" className="main_link">
                  home
                </a>
              </li>
              <li>
                <a href="/innerpages/about" className="main_link">
                  About
                </a>
              </li>
              <li>
                <a href="/innerpages/portfolio" className="main_link">
                  cases
                </a>
              </li>
              <li>
                <a href="/innerpages/blog" className="main_link">
                  Blog
                </a>
              </li>
              <li>
                <a href="/innerpages/contact" className="main_link">
                  contact
                </a>
              </li>
            </ul>
          </div>
        </div>
        <span className="clss">
          <i className="la  la-times"></i>
        </span>
      </div>
    </>
  );
}

export default Menu;
