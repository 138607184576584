import React from 'react';

function About() {
  return (
    <section className="tc-about-style3">
      <div className="top-info">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="info">
                <h5 className="fsz-30 text-uppercase mb-80"> Since 2012 </h5>
                <div className="text fsz-18 mb-15">
                As architect always want to turning dream in to reality with at client ease.

Every client small or big, they invest their money, time, resource, family values and very importantly sentimental to the building/interior work they make.

We personally want to take this to another level by creative design, detailing, sourcing, construction, lighting, styling with transparent, efficient and systematic manner to complete it on time.

To take one more level up, we design the building as per your astrological chart position based vastu planning to create, success, happiness, joy, health & wealth to my client that we call it design beyond philosophy for your best future & sustainable world.

                </div>
                <div className="text fsz-18 mb-15">
                DB ARCHITEKT is an award winning creative consultancy firm dealing in Architectural & Interior design consulting across India. We started Practice in 2012 in the name of QONE Design and Rebrand as DB Architekt in 2018 with unique Design Philosophy of “Design Beyond” for happy client.
                </div>
                <a
                  href="#"
                  className="butn borderd mt-70 bg-white py-2 px-3 text-uppercase ltspc-1 hover-bg-yellow1"
                >
                  <span>
                    About us <i className="small ms-1 ti-arrow-top-right"></i>
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-8 offset-lg-1">
              <div className="numbers-boxes">
                <div
                  className="sm-card js-splittext-lines"
                  data-speed="1"
                  data-lag="0.4"
                >
                  <h4 className="fsz-70 mb-30"> 52 </h4>
                  <p className="p text-uppercase">
                    awards & <br /> recognitons
                  </p>
                </div>
                <div
                  className="md-card js-splittext-lines"
                  data-speed="1"
                  data-lag="0.8"
                >
                  <h3 className="fsz-90 mb-90 fw-500"> 154 </h3>
                  <p className="p text-uppercase">
                    completed projects <br /> in 26+ cities
                  </p>
                </div>
                <div
                  className="lg-card js-splittext-lines"
                  data-speed="1"
                  data-lag="0.2"
                >
                  <h2 className="fw-500"> 15 </h2>
                  <h6 className="text-uppercase text-end fsz-18 lh-4">
                    years of <br /> experience
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="/home3_construction/assets/img/about_pat.png"
          alt=""
          className="pattern"
        />
      </div>
      <div className="img-content wow">
        <img
          src="/home3_construction/assets/img/about.jpg"
          alt=""
          className="img-cover main-img"
        />
      </div>
    </section>
  );
}

export default About;
